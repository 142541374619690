import { logger } from '@/libs/logger';
import { dispatchGetCompany, useAppContext } from '@/store';
import { useAuth } from '@/store/auth';
import {
  useCreateReservationClaimMutation,
  useGetReservationsByGuestIdQuery,
} from '@/store/reservations/api-slice';
import { Reservation } from '@/store/reservations/types';
import { useGetGuestUserQuery } from '@/store/services/userApi';
import { useMedia } from '@operto/ui';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import AppConfig from '../../assets/configurations';
import { Splash } from '../components';
import { useClaimError } from '../providers/ClaimErrorProvider';
import { Error } from './Error';
import { NoReservation } from './NoReservation';
import { PoweredByView } from './PoweredByView';
import ReservationCard from './ReservationCard';

export const ReservationList = () => {
  const { urlConfig, setUrlConfig } = useAppContext();
  const navigation = useNavigation();
  const { isDesktop } = useMedia();
  const {
    authState: {
      decodedToken: { isAdmin },
    },
  } = useAuth();
  const { setShowClaimError } = useClaimError();
  const [isReservationClaimed, setIsReservationClaimed] = useState(false);
  const [hasDoneClaimProcess, setHasDoneClaimProcess] = useState(false);
  const [hasScreenMounted, setHasScreenMounted] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [hasClaimError, setHasClaimError] = useState(false);

  // Check if there is a deeplink
  const { id: companyReservationCode } = urlConfig;
  const reservationCode = companyReservationCode.split('_')[1];
  const hasReservationCode = !!reservationCode;

  const navigateToHome = useCallback(
    async (newCompanyCode: string, newReservationCode: string) => {
      await dispatchGetCompany(`${newCompanyCode}_${newReservationCode}`);
      setUrlConfig({
        ...urlConfig,
        id: `${newCompanyCode}_${newReservationCode}`,
      });
      navigation.navigate('Home');
    },
    [navigation, setUrlConfig, urlConfig],
  );

  const renderCard = useCallback(
    ({ item }: { item: Reservation }) => (
      <TouchableOpacity
        onPress={() => navigateToHome(item.companyCode, item.reservationCode)}>
        <ReservationCard {...item} aspectRatio={1} />
      </TouchableOpacity>
    ),
    [navigateToHome],
  );

  const { data: guestServiceUserData } = useGetGuestUserQuery();
  const guestId = guestServiceUserData ? guestServiceUserData?.id : '';

  const [createReservationClaim] = useCreateReservationClaimMutation();

  const {
    data: reservationData,
    refetch: refetchReservations,
    isSuccess: isSuccessGetReservations,
    isError: hasError,
    isLoading: isReservationsLoading,
  } = useGetReservationsByGuestIdQuery(guestId, { skip: !guestId });

  // Claim the reservation
  useEffect(() => {
    const claimReservation = async () => {
      if (
        !isSuccessGetReservations ||
        !reservationData?.reservations ||
        isReservationsLoading ||
        hasError ||
        hasDoneClaimProcess
      ) {
        return;
      }

      setHasDoneClaimProcess(true);

      const isReservationInList = reservationData?.reservations.some(
        reso => reso.reservationCode === reservationCode,
      );

      if (!isReservationInList && !isAdmin && hasReservationCode && guestId) {
        try {
          const claimResponse = await createReservationClaim({
            reservationCode: reservationCode,
            guestId: guestId,
          }).unwrap();

          if (claimResponse.error) {
            setHasClaimError(true);
            logger.error('Error claiming reservation: ', claimResponse.error);
            return;
          } else {
            refetchReservations();
          }

          logger.info('Claimed reservation: ', claimResponse);
        } catch (error) {
          logger.error('Error claiming reservation');
          setShowClaimError(true);
          setHasClaimError(true);
        }
      }

      setIsReservationClaimed(true);
    };

    claimReservation();
  }, [
    isAdmin,
    createReservationClaim,
    guestId,
    hasReservationCode,
    reservationData,
    reservationCode,
    isSuccessGetReservations,
    setShowClaimError,
    isReservationClaimed,
    refetchReservations,
    hasDoneClaimProcess,
    isReservationsLoading,
    hasError,
  ]);

  // Redirect to home if there is only one reservation or if there is a deep link
  useEffect(() => {
    if (!urlConfig.id) {
      navigation.setOptions({ headerShown: true });
      setHasScreenMounted(true);
      return;
    }

    if (
      !isAdmin &&
      (!hasDoneClaimProcess || !isReservationClaimed || hasScreenMounted)
    ) {
      return;
    }

    if (hasClaimError) {
      navigation.setOptions({ headerShown: true });
      setHasScreenMounted(true);
      return;
    }

    setHasScreenMounted(true);

    if (hasReservationCode) {
      navigation.navigate('Home');
      setIsRedirecting(true);

      return;
    }

    if (reservationData?.reservations.length === 1) {
      navigateToHome(
        reservationData?.reservations[0].companyCode,
        reservationData?.reservations[0].reservationCode,
      );
      setIsRedirecting(true);

      return;
    }

    navigation.setOptions({ headerShown: true });
  }, [
    hasReservationCode,
    isSuccessGetReservations,
    navigateToHome,
    navigation,
    reservationData?.reservations,
    hasDoneClaimProcess,
    hasScreenMounted,
    hasClaimError,
    isReservationClaimed,
    isAdmin,
    urlConfig.id,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation]);

  // Reset values when focusing the page and this page was already mounted before
  // https://reactnavigation.org/docs/navigation-lifecycle/
  useFocusEffect(
    useCallback(() => {
      if (isRedirecting) {
        navigation.setOptions({ headerShown: true });
        setIsRedirecting(false);
      }
    }, [isRedirecting, navigation]),
  );

  if (
    !hasScreenMounted ||
    !hasDoneClaimProcess ||
    isReservationsLoading ||
    isRedirecting
  ) {
    return <Splash />;
  }

  if (hasError) {
    return <Error onRetry={refetchReservations} />;
  }

  return (
    <FlatList
      key={isDesktop ? 2 : 1}
      data={reservationData?.reservations}
      renderItem={renderCard}
      numColumns={isDesktop ? 2 : 1}
      style={styles.list}
      contentContainerStyle={styles.contentContainer}
      ListEmptyComponent={<NoReservation />}
      ListFooterComponent={<PoweredByView />}
      ListFooterComponentStyle={styles.footer}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    width: '100%',
    alignSelf: 'center',
  },
  contentContainer: {
    alignSelf: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  footer: {
    alignSelf: 'center',
    paddingVertical: AppConfig.Styles.getAdjustedSize(30),
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
});

export default ReservationList;
