import { logger } from '@/libs/logger';
import useLocalization from '@localizations';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  HelperText,
  MD3DarkTheme,
  MD3LightTheme,
  Text,
  useTheme,
} from 'react-native-paper';
import OpertoActivityIndicator from './OpertoActivityIndicator';
import OtpInput from './OtpInput';
import RegistrationLayout from './RegistrationLayout';
import { CODE_LENGTH, COUNT_TO_RESEND_CODE } from './constants';
import {
  getRegistrationInfoFromAsyncStorage,
  removeRegistrationInfoFromAsyncStorage,
  respondToAuthChallenge,
  setRegistrationInfoToAsyncStorage,
  signInUser,
} from './utils';

export default function RequestOtp() {
  const { t } = useLocalization();
  const navigation = useNavigation();
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [countToResend, setCountToResend] = useState(0);
  const [email, setEmail] = useState('');
  const [session, setSession] = useState('');
  const { colors } = useTheme();

  const styles = StyleSheet.create({
    container: { alignItems: 'center' },
    sentCodeMessage: {
      ...MD3DarkTheme.fonts.headlineMedium,
      color: 'white',
      marginBottom: 16,
      textAlign: 'center',
    },
    emailSentMessage: {
      ...MD3DarkTheme.fonts.bodyLarge,
      color: 'white',
      marginBottom: 16,
      textAlign: 'center',
    },
    resendButton: {
      width: 'auto',
      height: 40,
      marginBottom: 16,
      marginTop: 16,
      justifyContent: 'center',
    },
    helperText: {
      textAlign: 'center',
    },
  });

  const setNewSession = async (newSession: string) => {
    setSession(newSession);
    await setRegistrationInfoToAsyncStorage({ session: newSession, email });
  };

  const respondToChallenge = async (completeOtp: string) => {
    setVerifyingCode(true);

    try {
      const { userAuthorized, session: newSession } =
        await respondToAuthChallenge(session, email, completeOtp);

      if (userAuthorized) {
        await removeRegistrationInfoFromAsyncStorage();
      } else {
        setOtp('');
        setVerifyingCode(false);

        if (newSession) {
          setNewSession(newSession);
        }

        setOtpError(true);
      }
    } catch (error) {
      setOtp('');
      setVerifyingCode(false);

      logger.error(error);
      setOtpError(true);
    }
  };

  const onResendClick = async () => {
    if (countToResend > 0) {
      return;
    }

    setCountToResend(COUNT_TO_RESEND_CODE);

    const { session: newSession } = await signInUser(email);

    if (newSession) {
      setNewSession(newSession);
    }
  };

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);

    if (newOtp.length === CODE_LENGTH) {
      respondToChallenge(newOtp);
    }
  };

  useEffect(() => {
    let countTimeout: NodeJS.Timeout;
    if (countToResend) {
      countTimeout = setTimeout(() => {
        setCountToResend(prevCount => prevCount - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(countTimeout);
    };
  }, [countToResend]);

  useFocusEffect(() => {
    const getRegistrationInfo = async () => {
      if (!email && !session) {
        const registrationInfo = await getRegistrationInfoFromAsyncStorage();

        if (registrationInfo) {
          const { session: newSession, email: newEmail } = registrationInfo;

          if (newSession && newEmail) {
            setEmail(newEmail);
            setSession(newSession);
          } else {
            navigation.navigate('Auth');
          }
        } else {
          navigation.navigate('Auth');
        }
      }
    };

    getRegistrationInfo();
  });

  return (
    <RegistrationLayout
      isRequestingOtp
      justifyContent={verifyingCode ? 'center' : 'flex-start'}>
      {verifyingCode ? (
        <OpertoActivityIndicator message={t('verification_code_check')} />
      ) : (
        <View style={styles.container}>
          <Text style={styles.sentCodeMessage}>
            {t('verification_code_title')}
          </Text>

          <Text style={styles.emailSentMessage}>
            {`${t('verification_code_body')}${email}`}
          </Text>

          <OtpInput
            value={otp}
            onChange={handleOtpChange}
            mode="outlined"
            textColor={MD3DarkTheme.colors.onSurface}
            outlineColor={MD3DarkTheme.colors.outline}
            outlineStyle={{ backgroundColor: 'transparent' }}
            activeOutlineColor={colors.primaryContainer}
            error={otpError}
            isLoading={verifyingCode}
            codeLength={CODE_LENGTH}
            width={150}
            height={56}
          />

          <HelperText
            type="error"
            visible={!!otpError}
            style={styles.helperText}
            theme={{ colors: MD3DarkTheme.colors }}>
            {t('verification_code_error_status')}
          </HelperText>

          <Button
            testID="resend-code-button"
            mode="outlined"
            style={styles.resendButton}
            labelStyle={MD3LightTheme.fonts.labelLarge}
            textColor={
              countToResend
                ? MD3DarkTheme.colors.onSurfaceDisabled
                : colors.primaryContainer
            }
            onPress={onResendClick}>
            {`${t('verification_code_resend')} ${
              countToResend ? `${countToResend}s` : ''
            }`}
          </Button>
        </View>
      )}
    </RegistrationLayout>
  );
}
