import { useEffect, useRef } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInput as TextInputNative,
  View,
  ViewStyle,
} from 'react-native';
import { MD3DarkTheme, TextInput } from 'react-native-paper';

const OtpInput = ({
  value,
  onChange,
  error,
  codeLength,
  mode,
  textColor,
  outlineColor,
  outlineStyle,
  contentStyle,
  activeOutlineColor,
  errorColor,
  isLoading = false,
  width = 56,
  height = 56,
  margin = 2,
}: OtpInputProps) => {
  const inputRef = useRef<TextInputNative>(null);

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputStyle: {
      width,
      height,
      margin,
      textAlign: 'center',
    },
  });

  const handleOtpChange = (newOtp: string) => {
    const sanitizedNewOtp = newOtp
      .trim()
      .replace(/[^0-9]/g, '')
      .slice(0, codeLength);

    onChange(sanitizedNewOtp);
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <View style={styles.container}>
      <TextInput
        ref={inputRef}
        testID="otp-input"
        error={error}
        mode={mode}
        maxLength={codeLength}
        value={value}
        autoComplete="off"
        autoFocus
        keyboardType="numeric"
        textColor={textColor}
        outlineColor={outlineColor}
        outlineStyle={outlineStyle}
        contentStyle={[contentStyle, { minWidth: '56px' }]}
        activeOutlineColor={activeOutlineColor}
        theme={{ colors: { error: errorColor ?? MD3DarkTheme.colors.error } }}
        style={styles.inputStyle}
        onChangeText={newValue => handleOtpChange(newValue)}
        disabled={isLoading}
      />
    </View>
  );
};

export default OtpInput;

interface OtpInputProps {
  value: string;
  codeLength: number;
  onChange: (otp: string) => void;
  error?: boolean;
  mode?: 'outlined' | 'flat';
  textColor?: string;
  outlineColor?: string;
  outlineStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  activeOutlineColor?: string;
  errorColor?: string;
  width?: number;
  height?: number;
  margin?: number;
  isLoading?: boolean;
}
